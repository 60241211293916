import {
    Input,
    Output,
    Component,
    EventEmitter,
    SimpleChanges,
    ViewChild,
    OnChanges,
    OnInit
} from '@angular/core';
import moment from 'moment';
import { isObject, mapOption, twoDigit } from '../utils';
import { SelectOption } from '../interfaces/object-interfaces';

@Component({
    selector: 'pro-time-dropdown',
    template: `
        <div class="Time Time-Dropdown" fxLayout="row" fxLayoutAlign="start center" #timeBlockEl>
            <div fxLayout="row" fxLayoutAlign="start center">
                <pro-select [value]="timeData.hours"
                        [config]="{ label: config.label, key: 'id', disableSearch: true, floatLabel: 'always', isNone: true, hideArrow: true }"
                        [options]="hoursOptions"
                        (onChange)="set('hours', $event)"
                        [disabled]="disabled"></pro-select>
                @if (!hideShorthand) { <span>{{ 'hour_shorthand' | translate }}</span> }
                <div fxFlex="5px"></div>
                <pro-select [value]="timeData.minutes"
                        [config]="{ label: '', key: 'id', disableSearch: true, isNone: true, hideArrow: true }"
                        [options]="minutesOptions"
                        (onChange)="set('minutes', $event)"
                        [disabled]="disabled"></pro-select>
                @if (!hideShorthand) { <span>{{ 'minute_shorthand' | translate }}</span> }
            </div>
        </div>
    `
})

export class PromanTimeDropdownComponent implements OnInit, OnChanges {
    @Input() value: string;
    @Input() config: {
        label?: string;
        isUtcTime?: boolean;
    } = {};
    @Input() disabled: boolean;
    @Input() minutesPrecise: boolean;
    @Input() hideShorthand: boolean = false;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('timeBlockEl', { static: true }) timeBlockEl: any;

    isDateTimeModel: any = false;
    datetime: any;
    hours: any;
    minutes: any;
    timeData: { hours: string; minutes: string } = { hours: '', minutes: '' };
    hoursValues: string[] = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
    minutesValues: string[] = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '59'];
    minutesValuesPrecise: string[] = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
        '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40',
        '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
    hoursOptions: SelectOption[];
    minutesOptions: SelectOption[];
    utcOffset: number = 0;

    constructor(

    ) {
        this.hoursOptions = this.hoursValues.map(mapOption);
    }

    ngOnInit() {
        this.utcOffset = moment().utcOffset() / 60 - (moment().isDST() ? 1 : 0);
        this.minutesOptions = this.minutesPrecise ? this.minutesValuesPrecise.map(mapOption) : this.minutesValues.map(mapOption);
        this.init(this.value);
    }

    ngOnChanges(obj: SimpleChanges) {
        if (obj.value) {
            this.init(obj.value.currentValue);

        }

    };

    init(value: any) {
        if (isObject(value)) {
            value = value.date
        }

        let time;
        let timeParts;

        if (value) {

            if (value.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[+-]\d{2}:\d{2}/)) {
                this.datetime = moment(value);
                this.isDateTimeModel = true;

            } else if (value.match(/\d{2}:\d{2}:\d{2}[+-]\d{2}:\d{2}/)) {
                this.datetime = moment(moment().format('YYYY-MM-DD') + 'T' + value);
                this.isDateTimeModel = false;

            } else if (value.match(/\d{2}:\d{2}:\d{2}/)) {
                timeParts = value.split(':');

                this.datetime = moment()
                    .hours(timeParts[0])
                    .minutes(timeParts[1])
                    .seconds(timeParts[2]);
                this.isDateTimeModel = false;

            } else {
                throw Error('Invalid format for pmTime: ' + value);

            }

            time = this.datetime.format().split('T')[1].split(':');

            this.hours = time[0];
            this.minutes = time[1];

            if (this.config.isUtcTime) {
                this.hours = twoDigit(this.fromUtc(+this.hours));

            }

            this.timeData.hours = this.hours;
            this.timeData.minutes = this.minutes;

        } else {
            this.hours = null;
            this.minutes = null;

        }

    };

    update(value: string) {
        if (this.config.isUtcTime) {
            const parts = value.split(':');
            parts[0] = twoDigit(this.toUtc(+parts[0]));
            this.onChange.emit(parts.join(':'));
        } else {
            this.onChange.emit(value);
        }
    };

    set(property: any, value: any) {
        let dateTimeStr;

        this[property] = value;

        if (!this.hours) {
            this.hours = '00';
        }

        if (!this.minutes) {
            this.minutes = '00';
        }

        if (this.isDateTimeModel) {
            dateTimeStr = moment(this.datetime)
                .hours(parseInt(this.hours))
                .minutes(parseInt(this.minutes))
                .format();

            this.value = dateTimeStr;

            this.update(dateTimeStr);

        } else {

            this.value = this.hours + ':' + this.minutes + ':00';

            this.update(this.hours + ':' + this.minutes + ':00');

        }

    };

    toUtc(value: number) {
        return this.negativeFormat(value - this.utcOffset);
    }

    fromUtc(value: number) {
        return this.negativeFormat(value + this.utcOffset);
    }

    negativeFormat(value: number) {
        if (value >= 24) {
            return value - 24;
        }
        if (value < 0) {
            return value + 24;
        }
        return value;
    }

}
